<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import RadialBar from "../../common/charts/radialBar";
import { fetchContractsListApi } from "@/api/common";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Suivi des contrats",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, RadialBar, Multiselect },
  import: { fetchContractsListApi },
  data() {
    return {
      contractsList: [],
      showPenaltiesReportModal: false,
      penaltiesReport: [],
      charts: {
        servicesAnalytics: [],

        amountDistributionByService: {
          series: [],
          chartOptions: {
            chart: {
              height: 300,
              type: "radialBar",
            },
            plotOptions: {
              radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                  margin: 5,
                  size: "35%",
                  background: "transparent",
                  image: undefined,
                },
                track: {
                  show: true,
                  startAngle: undefined,
                  endAngle: undefined,
                  background: "#f2f2f2",
                  strokeWidth: "97%",
                  opacity: 1,
                  margin: 12,
                  dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5,
                  },
                },
                dataLabels: {
                  name: {
                    show: true,
                    fontSize: "16px",
                    fontWeight: 600,
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: "14px",
                    offsetY: 4,
                    formatter: function(val) {
                      return val + "%";
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    color: "#373d3f",
                    fontSize: "16px",
                    fontFamily: undefined,
                    fontWeight: 600,
                    formatter: function(w) {
                      return (
                        w.globals.seriesTotals.reduce(function(a, b) {
                          return a + b;
                        }, 0) + "%"
                      );
                    },
                  },
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
            colors: [],
            labels: [],
            legend: {
              show: false,
            },
          },
        },

        contractMonthlyOverview: {
          series: [],
          chartOptions: {
            tooltip: {
              fixed: {
                enabled: false,
              },
              y: {
                formatter: function(val) {
                  return new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "DZD",
                  }).format(val);
                },
              },
              marker: {
                show: false,
              },
            },
            chart: {
              height: 240,
              type: "line",
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
              width: 2,
              dashArray: [0, 0, 3],
            },
            fill: {
              type: "solid",
              opacity: [0.15, 0.05, 1],
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ],
            },
            colors: ["#f1b44c", "#3452e1", "#50a5f1"],
          },
        },

        // formatter: function (val) {
        //     return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(val)
        // }

        contractMonthlyConsumption: {
          series: [
            {
              name: "series1",
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              height: 320,
              type: "line",
              toolbar: "false",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#556ee6"],
            labels: [],
            stroke: {
              curve: "smooth",
              width: 3,
            },

            tooltip: {
              fixed: {
                enabled: false,
              },
              y: {
                formatter: function(val) {
                  return new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "DZD",
                  }).format(val);
                },
              },
              marker: {
                show: false,
              },
            },
          },
        },

        hseChart: {
          series: [
            {
              name: "series1",
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              height: 320,
              type: "bar",
              toolbar: "false",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 8,
                opacity: 0.2,
              },
            },
            dataLabels: {
              enabled: false,
            },
            colors: ["#556ee6"],
            labels: [],
            stroke: {
              curve: "smooth",
              width: 3,
            },

            tooltip: {
              fixed: {
                enabled: false,
              },
              y: {
                formatter: function(val) {
                  return val + "/10";
                },
              },
              marker: {
                show: false,
              },
            },
          },
        },
      },
      title: "Tableau de bord, Contrat N°: ",
      showChangeContractModal: false,
      contractData: {
        ref: "",
        object: "",
        type: "",
        supplier: "",
        startDate: "",
        endDate: "",
        status: "",
      },
      financeData: {
        planned_amount: "0.00 DA",
        invoicedAmnt: "0.00 DA",
        invoicedPercentage: "0.00",
        paidAmnt: "0.00 DA",
        paidPercentage: "0.00",
        leftAmnt: "0.00 DA",
        leftPercentage: "0.00",
        thisMonthConsumption: "0.00 DA",
        lastMonthConsumption: "0.00 DA",
        countSuppliersInvoices: "0",
      },

      penaltiesData: {
        hasPenalties: false,
        penalties: [],
        totalAmnt: "0.00 DA",
      },

      warrantiesData: {
        hasWarranties: false,
        totalAmnt: "0.00 DA",
      },

      odsByService: [],
      odsByServiceByCamp: [],

      hseData: {
        show: false,
        globalAvg: "0.00/10",
        controls: [],
        emoji: "sad",
      },
      invoiceData: {},
      ovFile: false,
      modelId: "",
      modelType: "",
      comments: "",
      contractIdChange: "",
      items: [
        {
          text: "Suivi & Gestion",
          to: { name: "monitoring_and_management" },
        },
        {
          text: "Suivi des contrats",
          to: { name: "monitoring_and_management.contract" },
        },
        {
          text: "",
          active: true,
        },
      ],
    };
  },

  created() {
    this.getContractDashboard();
  },

  methods: {
    _updateAmountDistributionByServiceChart(contractDashboardChart) {
      this.charts.amountDistributionByService.series =
        contractDashboardChart.amountDistributionByService.series;
      this.charts.amountDistributionByService.chartOptions = {
        labels: contractDashboardChart.amountDistributionByService.labels,
        colors: contractDashboardChart.amountDistributionByService.colors,
      };
    },

    _updateHseChart(contractHseChart) {
      this.charts.hseChart.series = [
        {
          name: "Moyenne: ",
          data: contractHseChart.series,
        },
      ];

      this.charts.hseChart.chartOptions = {
        labels: contractHseChart.labels,
      };
    },

    _updateContractMonthlyConsumptionChart(contractDashboardChart) {
      this.charts.contractMonthlyConsumption.series = [
        {
          name: "Consommation en DA",
          data: contractDashboardChart.contractMonthlyConsumption.series,
        },
      ];

      this.charts.contractMonthlyConsumption.chartOptions = {
        labels: contractDashboardChart.contractMonthlyConsumption.labels,
      };
    },

    _updateContractMonthlyOverviewChart(contractDashboardChart) {
      (this.charts.contractMonthlyOverview.series =
        contractDashboardChart.monthlyOverview.series),
        (this.charts.contractMonthlyOverview.chartOptions = {
          labels: contractDashboardChart.monthlyOverview.labels,
          colors: contractDashboardChart.monthlyOverview.colors,
        });
    },

    fetchContractsList() {
      var loader = this.$loading.show();
      fetchContractsListApi()
        .then((res) => {
          loader.hide();
          this.contractsList = res.data.list;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {
          //Perform action in always
        });
    },

    onPenaltyReportModalClose(){
      this.showPenaltiesReportModal = false;
      this.penaltiesReport = [];
    },

    onPenaltyNameLnkClick(penalty) {
      console.log(penalty);
      // show penalties details
      var loader = this.$loading.show();
      // get all penalties data
      this.$http
        .post("/contracts/contracts/getPenaltyDetails", {
          contractId: this.$route.params.uid,
          penaltyId: penalty.id,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          this.penaltiesReport = res.data.penalties;
          this.showPenaltiesReportModal = true;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
      // show penalties details modal
    },

    changeContractClick() {
      this.fetchContractsList();
      this.contractIdChange = this.contractData;
      this.showChangeContractModal = true;
    },

    async getContractDashboard() {
      var loader = this.$loading.show();
      await this.$http
        .post("/contracts/contracts/getContractDashboard", {
          contractId: this.$route.params.uid,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var contract = res.data.contract;
          var contractDashboard = res.data.contractDashboard;
          var contractDashboardChart = res.data.contractDashboardChart;
          var hseChart = res.data.hseDashboard.hseDashboard;
          switch (status) {
            case 200:
              this.title = "Tableau de bord, Contrat N°: ";
              this.title = this.title + contract.ref;
              this.items[this.items.length - 1].text =
                "Contrat N°: " + contract.ref;
              this.contractData.ref = contract.ref;
              this.contractData.object = contract.object;
              this.contractData.type = contract.type;
              this.contractData.supplier = contract.supplier.prestname;
              this.contractData.startDate = contract.startDate;
              this.contractData.endDate = contract.endDate;
              this.contractData.status = contract.status;

              // ods Data
              this.financeData.amountOds =
                contractDashboard.odsAmnt.withCurrency;
              this.financeData.amountPercentage =
                contractDashboard.odsAmnt.percentage;

              // penalties
              this.penaltiesData.hasPenalties =
                contractDashboard.penaltiesData.hasPenalties;
              this.penaltiesData.penalties =
                contractDashboard.penaltiesData.penalties;
              this.penaltiesData.totalAmnt =
                contractDashboard.penaltiesData.totalAmnt;

              this.warrantiesData.hasWarranties =
                contractDashboard.warrantiesData.hasWarranties;
              this.warrantiesData.totalAmnt =
                contractDashboard.warrantiesData.amount.withCurrency;

              this.odsByService = contractDashboard.odsByService;
              this.odsByServiceByCamp = contractDashboard.odsByServiceByCamp;
              this.financeData.countSuppliersInvoices =
                contractDashboard.misc.countSupplierInvoices.formated;

              this.hseData.show = contractDashboard.hse_controls.available;
              this.hseData.globalAvg =
                contractDashboard.hse_controls.global_avg.formated;
              this._updateHseEmoji(
                contractDashboard.hse_controls.global_avg.plain
              );
              this.hseData.controls = contractDashboard.hse_controls.controls;

              this.financeData.planned_amount =
                contract.amounts.planned_amount.withCurrency;

              this.financeData.thisMonthConsumption =
                contractDashboard.consumption.thisMonth;
              this.financeData.lastMonthConsumption =
                contractDashboard.consumption.lastMonth;

              this.financeData.differenceType =
                contractDashboard.consumption.differenceType;
              this.financeData.differencePercente =
                contractDashboard.consumption.differencePercente;

              this.financeData.invoicedAmnt =
                contractDashboard.invoicedAmnt.withCurrency;
              this.financeData.paidAmnt =
                contractDashboard.paidAmnt.withCurrency;
              this.financeData.leftAmnt =
                contractDashboard.leftAmnt.withCurrency;
              this.financeData.invoicedPercentage =
                contractDashboard.invoicedAmnt.percentage;
              this.financeData.paidPercentage =
                contractDashboard.paidAmnt.percentage;
              this.financeData.leftPercentage =
                contractDashboard.leftAmnt.percentage;
              this.charts.servicesAnalytics =
                contractDashboardChart.servicesAnalytics;

              this._updateAmountDistributionByServiceChart(
                contractDashboardChart
              );
              this._updateContractMonthlyConsumptionChart(
                contractDashboardChart
              );
              this._updateContractMonthlyOverviewChart(contractDashboardChart);
              this._updateHseChart(hseChart);

              break;

            case 500:
              loader.hide();
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    _updateHseEmoji(avg) {
      var sadLevel = 4;
      var mehLevel = 6;
      var happyLevel = 10;
      var emoji = "sad";

      if (avg >= 0 && avg <= sadLevel) {
        emoji = "sad";
      } else if (avg > sadLevel && avg <= mehLevel) {
        emoji = "confused";
      } else if (avg > mehLevel && avg < happyLevel) {
        emoji = "smile";
      } else {
        emoji = "love";
      }
      this.hseData.emoji = emoji;
    },

    changeContractHandle() {
      var contractUid = this.contractIdChange.uuid;
      this.$route.params.uid = contractUid;
      this.$router.push({
        name: "monitoring_and_management.contract.dashboard",
        params: { uid: contractUid },
      });
      this.showChangeContractModal = false;
      this.getContractDashboard();
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <Modal v-model="showPenaltiesReportModal" @close="onPenaltyReportModalClose" :title="`Rapport de pénalités`">
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table mb-0">
              <thead class="thead-light">
                <tr>
                  <th>ID</th>
                  <th>N° Facture</th>
                  <th>Date Facture</th>
                  <th>Montant HT</th>
                  <th>Montant de Pénalité</th>
                  <th>Montant Aprés pénalité</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(penalty, index) in penaltiesReport" v-bind:key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ penalty.invNo }}</td>
                  <td>{{ penalty.invoiceDate }}</td>
                  <td>{{ penalty.untaxedAmnt }}</td>
                  <td>{{ penalty.penaltyAmnt }}</td>
                  <td>{{ penalty.finalAmnt }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Modal>

    <Modal v-model="showChangeContractModal" :title="`Changer le contrat`">
      <form>
        <div class="row">
          <div class="col-md-12">
            <label for="formrow-inputCity">
              Contrat *
            </label>
            <multiselect
              v-model="contractIdChange"
              :searchable="true"
              track-by="id"
              open-direction="bottom"
              label="ref"
              :select-label="''"
              :options="contractsList"
              placeholder=""
              :allow-empty="false"
            >
              <template slot="singleContract" slot-scope="{ contract }">{{
                contract.ref
              }}</template>
            </multiselect>
          </div>
        </div>
        <br />

        <div>
          <button
            @click.prevent="changeContractHandle"
            class="btn btn-primary"
            type="submit"
          >
            <i class="fas fa-check"></i>
            Appliquer
          </button>
        </div>
      </form>
    </Modal>

    <div class="contractDashboard">
      <div class="row">
        <div class="col-xl-4">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="media">
                    <div class="mr-4">
                      <a @click.prevent="getContractDashboard"
                        ><i class="mdi mdi-cloud-refresh text-primary h1"></i
                      ></a>
                    </div>

                    <div class="media-body">
                      <div class="text-muted">
                        <h5 style="display: inline-block">
                          Contrat N°: {{ contractData.ref }}
                        </h5>
                        <a
                          @click.prevent="changeContractClick"
                          class="float-right"
                          style="display: inline-block"
                          href=""
                          >Changer le contrat</a
                        >
                        <p class="mb-1">
                          Objet: <b>{{ contractData.object }}.</b>
                        </p>
                        <p class="mb-0">
                          Type:
                          <span class="badge badge-primary">{{
                            contractData.type
                          }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body border-top">
                  <div class="row">
                    <div class="col-sm-3">
                      <div>
                        <p class="text-muted mb-2">Prestataire</p>
                        <span class="badge badge-info">{{
                          contractData.supplier
                        }}</span>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <p class="text-muted mb-2">Date début</p>
                        <h5>
                          <span class="badge badge-success">{{
                            contractData.startDate
                          }}</span>
                        </h5>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <p class="text-muted mb-2">Date fin</p>
                        <h5>
                          <span class="badge badge-warning">{{
                            contractData.endDate
                          }}</span>
                        </h5>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div>
                        <p class="text-muted mb-2">Status</p>
                        <h5>
                          <span v-html="contractData.status"></span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card">
                <div class="card-body pb-0">
                  <div class="clearfix">
                    <h4 class="card-title mb-4">Informations Financières</h4>
                  </div>

                  <div class="text-muted text-center">
                    <p class="mb-2">
                      Montant disponible
                      <i
                        v-b-popover.hover="
                          `Le montant exploitable du contrat N°: ${contractData.ref} aprés le paiement des factures du prestataire: ${contractData.supplier}.`
                        "
                        class="fas fa-question-circle mr-2 helpIcon"
                      ></i>
                    </p>
                    <h4>
                      {{ financeData.leftAmnt }}
                    </h4>
                  </div>

                  <div class="table-responsive mt-4 mb-0">
                    <table class="table table-centered">
                      <tbody>
                        <tr>
                          <td>
                            <h5 class="font-size-14 mb-1">
                              Montant contractuel
                              <i
                                v-b-popover.hover="
                                  `Le montant du contrat N°: ${contractData.ref}`
                                "
                                class="fas fa-question-circle mr-2 helpIcon"
                              ></i>
                            </h5>
                            <p class="text-muted mb-0"></p>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ financeData.planned_amount }}
                              <span
                                class="badge badge-primary ml-1 align-bottom"
                              ></span>
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h5 class="font-size-14 mb-1">
                              Consommations (ODS)
                              <i
                                v-b-popover.hover="
                                  `La consommation des différents Ordres de services relatives a cet contrat.`
                                "
                                class="fas fa-question-circle mr-2 helpIcon"
                              ></i>
                            </h5>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ financeData.amountOds }}
                              <span
                                class="badge badge-primary ml-1 align-bottom"
                                >{{ financeData.amountPercentage }} %</span
                              >
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h5 class="font-size-14 mb-1">
                              Factures a payer
                              <i
                                v-b-popover.hover="
                                  `Le montant total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                                "
                                class="fas fa-question-circle mr-2 helpIcon"
                              ></i>
                            </h5>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ financeData.invoicedAmnt }}
                              <span class="badge badge-info ml-1 align-bottom"
                                >{{ financeData.invoicedPercentage }} %</span
                              >
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <h5 class="font-size-14 mb-1">
                              Factures Payé
                              <i
                                v-b-popover.hover="
                                  `Le montant total des factures payés du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                                "
                                class="fas fa-question-circle mr-2 helpIcon"
                              ></i>
                            </h5>
                            <p class="text-muted mb-0"></p>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ financeData.paidAmnt }}
                              <span class="badge badge-danger ml-1 align-bottom"
                                >{{ financeData.paidPercentage }} %</span
                              >
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="penaltiesData.hasPenalties" class="col-xl-12">
              <div class="card">
                <div class="card-body pb-0">
                  <div class="clearfix">
                    <h4 class="card-title mb-4">Pénalités</h4>
                  </div>

                  <div class="text-muted text-center">
                    <p class="mb-2">
                      Total pénalités
                      <i
                        v-b-popover.hover="
                          `Le montant total des pénalités appliqués dans le contrat N°: ${contractData.ref} pour le prestataire: ${contractData.supplier}.`
                        "
                        class="fas fa-question-circle mr-2 helpIcon"
                      ></i>
                    </p>
                    <h4>
                      {{ penaltiesData.totalAmnt }}
                    </h4>
                  </div>

                  <div class="table-responsive mt-4 mb-0">
                    <table class="table table-centered">
                      <tbody>
                        <tr
                          v-for="(penalty, index) in penaltiesData.penalties"
                          v-bind:key="index"
                        >
                          <td>
                            <h5 class="font-size-14 mb-1">
                              <a
                                href="javascript:void(0)"
                                @click.prevent="onPenaltyNameLnkClick(penalty)"
                                >{{ index }}</a
                              >
                              <i
                                v-b-popover.hover="
                                  `Le montant de la pénalité: ${index}`
                                "
                                class="fas fa-question-circle mr-2 helpIcon"
                              ></i>
                            </h5>
                            <p class="text-muted mb-0"></p>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ penalty.withCurrency }}
                              <span
                                class="badge badge-primary ml-1 align-bottom"
                              ></span>
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="warrantiesData.hasWarranties" class="col-xl-12">
              <div class="card">
                <div class="card-body pb-0">
                  <div class="clearfix">
                    <h4 class="card-title mb-4">Garanties</h4>
                  </div>

                  <div class="text-muted text-center">
                    <p class="mb-2">
                      Total garanties
                      <i
                        v-b-popover.hover="
                          `Le montant total des garanties appliqués dans le contrat N°: ${contractData.ref} pour le prestataire: ${contractData.supplier}.`
                        "
                        class="fas fa-question-circle mr-2 helpIcon"
                      ></i>
                    </p>
                    <h4>
                      {{ warrantiesData.totalAmnt }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card">
                <div class="card-body pb-0">
                  <div class="clearfix">
                    <h4 class="card-title mb-4">
                      Prestations
                      <i
                        v-b-popover.hover="
                          `Une synthèse sur les différents prestations du contrat N°: ${contractData.ref}.`
                        "
                        class="fas fa-question-circle mr-2 helpIcon"
                      ></i>
                    </h4>
                  </div>
                  <div
                    class="row mb-2 border-bottom"
                    v-for="service in charts.servicesAnalytics"
                    v-bind:key="service.name"
                  >
                    <div class="col-md-6">
                      <h5 class="font-size-14 mb-1">
                        <span class="badge badge-primary">{{
                          service.name
                        }}</span>
                      </h5>
                      <p class="text-muted mb-0">
                        Montant Contractuel: <br />
                        <b>{{ service.contractualAmnt }}</b>
                      </p>
                      <p class="text-muted mb-0">
                        Montant Réalisé:<br />
                        <b>{{ service.realizedAmnt }}</b>
                      </p>
                      <p class="text-muted mb-0">
                        Montant Disponible:<br />
                        <b>{{ service.leftAmnt }}</b>
                      </p>
                    </div>

                    <div class="col-md-6">
                      <RadialBar
                        :seriesvalue="service.realizationPercentage"
                        :Chartcolor="service.realizationColor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="hseData.show" class="col-xl-12">
              <div class="card">
                <div class="card-body pb-0">
                  <div class="clearfix">
                    <h4 class="card-title mb-4">HSE</h4>
                  </div>

                  <div class="text-muted text-center">
                    <p class="mb-2">Note Globale:</p>
                    <h4>
                      {{ hseData.globalAvg }}
                    </h4>
                    <img
                      class="rounded-circle avatar-sm"
                      :alt="hseData.globalAvg"
                      :title="hseData.globalAvg"
                      :src="
                        require('@/assets/images/base/controls/' +
                          hseData.emoji +
                          '.png')
                      "
                      data-holder-rendered="true"
                    />
                  </div>

                  <div class="table-responsive mt-4 mb-0">
                    <table class="table table-centered">
                      <tbody>
                        <tr
                          v-for="(elem, index) in hseData.controls"
                          v-bind:key="index"
                        >
                          <td>
                            <h5 class="font-size-14 mb-1">
                              {{ elem.type }}
                            </h5>
                            <p class="text-muted mb-0"></p>
                          </td>

                          <td></td>
                          <td>
                            <p class="text-muted mb-1"></p>
                            <h5 class="mb-0">
                              {{ elem.avg_formated }}
                              <span
                                class="badge badge-primary ml-1 align-bottom"
                              ></span>
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6">
                <div class="card">
                  <div class="card-body">
                    <h6 class="text-muted mb-4">
                      <i
                        class="fas fa-file-invoice-dollar h2 text-primary align-middle mb-0 mr-3"
                      ></i>
                      Factures Prestataire
                    </h6>

                    <div class="row">
                      <div class="col-md-5 text-center">
                        Nbr de factures
                        <i
                          v-b-popover.hover="
                            `Le nombre total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                          "
                          class="fas fa-question-circle mr-2 helpIcon"
                        ></i>
                        <h2>{{ financeData.countSuppliersInvoices }}</h2>
                      </div>
                      <div class="col-md-7 text-center">
                        Total Factures Impayés
                        <i
                          v-b-popover.hover="
                            `Le montant total des factures en attente de paiement du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                          "
                          class="fas fa-question-circle mr-2 helpIcon"
                        ></i>
                        <h2>{{ financeData.invoicedAmnt }}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="card">
                  <div class="card-body">
                    <h6 class="text-muted mb-4">
                      <i
                        class="fas fa-money-bill h2 text-warning align-middle mb-0 mr-3"
                      ></i>
                      Paiements
                    </h6>

                    <div class="row">
                      <div class="col-12 text-center">
                        Total paiements
                        <i
                          v-b-popover.hover="
                            `Le montant total des factures payés du prestataire: ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                          "
                          class="fas fa-question-circle mr-2 helpIcon"
                        ></i>
                        <h2>{{ financeData.paidAmnt }}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title mb-3">
                  Aperçu
                  <i
                    v-b-popover.hover="
                      `Comparaison de consommation des différentes prestations du contrat N°: ${contractData.ref} durant une année.`
                    "
                    class="fas fa-question-circle mr-2 helpIcon"
                  ></i>
                </h4>

                <div>
                  <div id="overview-chart" class="apex-charts" dir="ltr"></div>
                  <apexchart
                    class="apex-charts"
                    height="240"
                    type="line"
                    dir="ltr"
                    :series="charts.contractMonthlyOverview.series"
                    :options="charts.contractMonthlyOverview.chartOptions"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="clearfix">
                  <h4 class="card-title mb-4">Consommation Mensuelle</h4>
                </div>

                <div class="row">
                  <div class="col-lg-3">
                    <div class="text-muted">
                      <div class="mb-4">
                        <p>Ce mois</p>
                        <h4>{{ financeData.thisMonthConsumption }}</h4>
                        <div>
                          <span
                            class="badge badge-pill font-size-11 mr-1"
                            :class="
                              financeData.differenceType == 'drop'
                                ? 'badge-soft-success'
                                : 'badge-soft-danger'
                            "
                          >
                            <i
                              class="fas"
                              :class="
                                financeData.differenceType == 'drop'
                                  ? 'fa-angle-down'
                                  : 'fa-angle-up'
                              "
                            ></i>
                            {{ financeData.differencePercente }}%
                          </span>
                          Du mois précédent
                        </div>
                      </div>

                      <div class="mt-4">
                        <p class="mb-2">Dernier mois</p>
                        <h5>{{ financeData.lastMonthConsumption }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <apexchart
                      class="apex-charts"
                      dir="ltr"
                      height="320"
                      :series="charts.contractMonthlyConsumption.series"
                      :options="charts.contractMonthlyConsumption.chartOptions"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-6">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">
                      Répartition ODS / Prestation
                    </h4>
                    <div class="table-responsive mt-4">
                      <table class="table table-centered">
                        <thead>
                          <tr>
                            <th></th>
                            <th>N° ODS</th>
                            <th>Montant</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(elem, index) in odsByService"
                            v-bind:key="index"
                          >
                            <td style="width: 140px">
                              <h6 class="mb-0">{{ elem.name }}</h6>
                            </td>
                            <td style="width: 120px">
                              <h5 class="mb-0">{{ elem.countOds.formated }}</h5>
                            </td>
                            <td>
                              <h5>{{ elem.amntOds.withCurrency }}</h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title mb-4">
                      Réalisation Prestation / Base de vie
                    </h4>
                    <div class="table-responsive mt-4">
                      <table class="table table-centered">
                        <thead>
                          <tr>
                            <th></th>
                            <th
                              v-for="(camp, index) in odsByServiceByCamp.camps"
                              v-bind:key="index"
                            >
                              {{ camp }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(service,
                            index) in odsByServiceByCamp.services"
                            v-bind:key="index"
                          >
                            <td style="width: 140px">
                              <h6 class="mb-0">{{ service }}</h6>
                            </td>
                            <td
                              v-for="(servicesAmnt,
                              index) in odsByServiceByCamp.campHasDataForService"
                              v-bind:key="index"
                            >
                              <h5>
                                {{
                                  servicesAmnt[service]
                                    ? servicesAmnt[service].amntOds.withCurrency
                                    : "0.00 DA"
                                }}
                              </h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="clearfix">
                  <h4 class="card-title mb-4">
                    Contrôles HSE
                    <i
                      v-b-popover.hover="
                        `Une synthèse sur les différents contrôles HSE du prestataire : ${contractData.supplier} pour le contrat N°: ${contractData.ref}.`
                      "
                      class="fas fa-question-circle mr-2 helpIcon"
                    ></i>
                  </h4>
                </div>

                <div class="row">
                  <div class="col-lg-3">
                    <div class="text-muted text-center mt-5">
                      <p class="mb-2">Note Globale:</p>
                      <h4>
                        {{ hseData.globalAvg }}
                      </h4>
                      <img
                        class="rounded-circle avatar-sm"
                        :alt="hseData.globalAvg"
                        :title="hseData.globalAvg"
                        :src="
                          require('@/assets/images/base/controls/' +
                            hseData.emoji +
                            '.png')
                        "
                        data-holder-rendered="true"
                      />
                    </div>
                  </div>
                  <div class="col-lg-9">
                    <apexchart
                      class="apex-charts"
                      dir="ltr"
                      height="320"
                      :series="charts.hseChart.series"
                      :options="charts.hseChart.chartOptions"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
